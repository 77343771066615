// extracted by mini-css-extract-plugin
export var benefitsSectionMvp = "x_dy";
export var businessesSectionMvp = "x_dw";
export var clientsMvpSection = "x_dL";
export var devApproachSectionMvp = "x_dz";
export var domainExpertiseMvpSection = "x_dv";
export var launchProductSectionMvp = "x_dB";
export var launchProductSectionMvp__testimonial = "x_dC";
export var mvpProjLogoSection = "x_dJ";
export var ourAchievementsMvp = "x_dK";
export var servicesCardBorderIconMvpSection = "x_dt";
export var servicesDevExpertiseMvp = "x_dD";
export var servicesProcessMvpSection = "x_dx";
export var successStoriesMvpSection = "x_dG";
export var technologiesSectionMvp = "x_dF";
export var whyChooseMvpSection = "x_dH";